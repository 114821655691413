import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

const mock = [
  {
    title: 'How many replies can I generate?',
    subtitle:
      'You can generate as many replies as you need, but the number of replies depends on your pricing plan.',
  },
  {
    title: 'How can I get an invoice?',
    subtitle:
      'We’ll email you an invoice at the provided email address after each successful payment.',
  },
  {
    title: 'Is there any contract required?',
    subtitle:
      'No, there are no contracts, agreements or hidden fees. You can cancel anytime.',
  },
  {
    title: 'What languages does Cogni support?',
    subtitle:
      'Cogni lets you build bots in any language, but the Cogni platform is available only in English.',
  },
  {
    title: 'Where do you store data?',
    subtitle:
      'Cogni stores its customer information in an encrypted environment in the AWS cloud.',
  },
  {
    title: 'Do I need to provide my credit card details to register?',
    subtitle:
      'We don’t require your credit card details to start your free trial. We’ll need them when your trial is over and you decide to upgrade to a paid plan.',
  },
];

const Faq = () => {
  return (
    <Box>
      <Box marginBottom={4}>
        <Typography fontWeight={700} variant={'h4'} align={'center'}>
          Frequently asked questions
        </Typography>
      </Box>
      <Grid container spacing={4}>
        {mock.map((item, i) => (
          <Grid key={i} item xs={12} sm={6} md={4}>
            <Typography variant={'h6'} fontWeight={600} gutterBottom>
              {item.title}
            </Typography>
            <Typography color="text.secondary">{item.subtitle}</Typography>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Faq;
